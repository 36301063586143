import { decodeToken } from "@/@core/utils/decodeJwt";
import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/libs/i18n";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/home/Home.vue"),
      meta: {
        pageTitle: i18n.t("home"),
        breadcrumb: [
          {
            text: i18n.t("home"),
            active: true,
          },
        ],
      },
    },
    {
      path: "/employee",
      name: "employee",
      component: () => import("@/views/employee/UserList.vue"),
      meta: {
        pageTitle: i18n.t("employee"),
        breadcrumb: [
          {
            text: i18n.t("employee"),
            active: true,
          },
        ],
      },
    },
    {
      path: "/employee/view/:id",
      name: "view-employee",
      component: () => import("@/views/employee/view/UsersView.vue"),
      meta: {
        pageTitle: i18n.t("employee"),
        breadcrumb: [
          {
            text: i18n.t("employee"),
            active: true,
          },
        ],
        parent: "employee",
      },
    },
    {
      path: "/employee/edit/:id",
      name: "edit-employee",
      component: () => import("@/views/employee/manage/ManageUser.vue"),
      meta: {
        pageTitle: i18n.t("employees.edit"),
        breadcrumb: [
          {
            text: i18n.t("employees.edit"),
            active: true,
          },
        ],
        parent: "employee",
      },
    },

    {
      path: "/employee/add",
      name: "add-employee",
      component: () => import("@/views/employee/manage/ManageUser.vue"),
      meta: {
        pageTitle: i18n.t("employees.add"),
        breadcrumb: [
          {
            text: i18n.t("employees.add"),
            active: true,
          },
        ],
        // parent: "employee",
      },
    },
    //

    {
      path: "/evaluation",
      name: "evaluation",
      component: () => import("@/views/evaluation/UserList.vue"),
      meta: {
        pageTitle: i18n.t("evaluation"),
        breadcrumb: [
          {
            text: i18n.t("evaluation"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/report",
      name: "report",
      component: () => import("@/views/report/Report.vue"),
      meta: {
        pageTitle: i18n.t("report"),
        breadcrumb: [
          {
            text: i18n.t("report"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/evaluation/manage/:id?",
      name: "manage-evaluation",
      component: () => import("@/views/evaluation/manage/ManageEvaluation.vue"),
      meta: {
        pageTitle: i18n.t("evaluations.manage"),
        breadcrumb: [
          {
            text: i18n.t("evaluations.manage"),
            active: true,
          },
        ],
        parent: "evaluation",
      },
    },

    {
      path: "/setting",
      name: "setting",
      component: () => import("@/views/setting/Setting.vue"),
      meta: {
        pageTitle: i18n.t("setting"),
        breadcrumb: [
          {
            text: i18n.t("setting"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/contract-us",
      name: "contract-us",
      component: () => import("@/views/contract-us/ContractUs.vue"),
      meta: {
        pageTitle: i18n.t("contract_us"),
        breadcrumb: [
          {
            text: i18n.t("contract_us"),
            active: true,
          },
        ],
      },
    },

    //====================== ====================== ======================//
    //======================       Admin Route       ======================//
    //====================== ====================== ======================//
    {
      path: "/admin/",
      name: "admin-home",
      component: () => import("@/views/admin/home/Home.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("home"),
        breadcrumb: [
          {
            text: i18n.t("home"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/mange-company/:id?",
      name: "admin-home",
      component: () => import("@/views/admin/home/Home.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("home"),
        breadcrumb: [
          {
            text: i18n.t("home"),
            active: true,
          },
        ],
      },
    },

    // {
    //   path: "/admin/employee",
    //   name: "admin-employee",
    //   component: () => import("@/views/admin/employee/UserList.vue"),
    //   meta: {
    //     role: "admin",
    //     pageTitle: i18n.t("employee"),
    //     breadcrumb: [
    //       {
    //         text: i18n.t("employee"),
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // {
    //   path: "/admin/employee/:id",
    //   name: "admin-view-employee",
    //   component: () => import("@/views/admin/employee/view/UsersView.vue"),
    //   meta: {
    //     role: "admin",
    //     pageTitle: i18n.t("employees.view"),
    //     breadcrumb: [
    //       {
    //         text: i18n.t("employees.view"),
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/admin/company",
      name: "admin-company-list",
      component: () => import("@/views/admin/company/CompanyList.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("company"),
        breadcrumb: [
          {
            text: i18n.t("company"),
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/company/:id",
      name: "admin-view-company",
      component: () => import("@/views/admin/company/view/ViewCompany.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("companies.view"),
        breadcrumb: [
          {
            text: i18n.t("companies.view"),
            active: true,
          },
        ],
        parent: "admin-company-list",
      },
    },

    {
      path: "/admin/add-company",
      name: "admin-add-company",
      component: () => import("@/views/admin/company/manage/ManageCompany.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("companies.add"),
        breadcrumb: [
          {
            text: i18n.t("companies.add"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/edit-company/:id",
      name: "admin-edit-company",
      component: () => import("@/views/admin/company/manage/ManageCompany.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("companies.edit"),
        breadcrumb: [
          {
            text: i18n.t("companies.edit"),
            active: true,
          },
        ],
        parent: "admin-add-company",
      },
    },

    {
      path: "/admin/evaluation",
      name: "admin-evaluation-main",
      component: () => import("@/views/admin/evaluation/main/Main.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("evaluation"),
        breadcrumb: [
          {
            text: i18n.t("evaluation"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/setting-evaluation",
      name: "admin-setting-evaluation",
      component: () => import("@/views/admin/evaluation/setting/Setting.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("evaluations.setting"),
        breadcrumb: [
          {
            text: i18n.t("evaluations.setting"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/approve-evaluation",
      name: "admin-evaluation-approve",
      component: () =>
        import("@/views/admin/evaluation/notification/Notification.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("evaluations.approve"),
        breadcrumb: [
          {
            text: i18n.t("evaluations.approve"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/approve-employee/:id",
      name: "admin-approve-employee",
      component: () =>
        import("@/views/admin/evaluation/notification/approve/Approve.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("evaluations.approve"),
        breadcrumb: [
          {
            text: i18n.t("evaluations.approve"),
            active: true,
          },
        ],
        parent: "admin-evaluation-approve",
      },
    },

    {
      path: "/admin/report",
      name: "admin-report",
      component: () => import("@/views/admin/report/Report.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("report"),
        breadcrumb: [
          {
            text: i18n.t("report"),
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/setting",
      name: "admin-setting",
      component: () => import("@/views/admin/setting/AccountSetting.vue"),
      meta: {
        role: "admin",
        pageTitle: i18n.t("account_setting"),
        breadcrumb: [
          {
            text: i18n.t("account_setting"),
            active: true,
          },
        ],
      },
    },
    //====================== ====================== ======================//
    //======================     End Admin Route     ======================//
    //====================== ====================== ======================//

    //
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/not-permission",
      name: "not-permission",
      component: () => import("@/views/NotPermission.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});
router.beforeEach((to, _, next) => {
  const isLoggedIn = decodeToken();

  if (!isLoggedIn && to.name !== "login") {
    return next({ name: "login" });
  }

  if (isLoggedIn && to.name === "login") {
    let routeName = "";
    isLoggedIn.type === "admin"
      ? (routeName = "admin-home")
      : (routeName = "home");
    return next({ name: routeName });
  }

  if (to.meta.role === "admin" && isLoggedIn.type !== "admin") {
    return next({ name: "not-permission" });
  }

  return next();
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
