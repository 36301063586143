import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";

import dayjs from "dayjs";
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");
// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

const apiURL = process.env.VUE_APP_API_URL;
Vue.prototype.$apiURL = apiURL;
Vue.prototype.$dayjs = dayjs;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
