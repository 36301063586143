const jwt = require("jsonwebtoken");
import router from "@/router";

export const decodeToken = () => {
  try {
    const secret =
      "a61b3ac2ade91dsagafasd4889ff8e99e80b98fba4b0a9fb8b1b020033c80222vcxvcdx9d05dde2b9eed981981207dd8045fb1f98fc1d7326f71758fe87";
    const token = localStorage.getItem("accessToken");
    var decoded = jwt.verify(token, secret);

    return decoded;
  } catch (err) {
    localStorage.removeItem("accessToken");
    return false;
  }
};
